<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.paymentDays')" />

    <v-card-text>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="myObj.name"
                :label="this.$vuetify.lang.t('$vuetify.userName')"
                :rules="generalRule"
              />
            </v-col>
            <v-col cols="12" class="pt-2 pb-0">
              <v-textarea
                v-model="myObj.note"
                outlined
                :label="this.$vuetify.lang.t('$vuetify.note')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />
      <v-btn color="red darken-2" outlined @click="cancelForm" width="120">{{
        $vuetify.lang.t("$vuetify.cancel")
      }}</v-btn>
      <v-btn color="green darken-2" @click="validate" width="120">{{
        $vuetify.lang.t("$vuetify.save")
      }}</v-btn>
      <v-spacer />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
export default {
  name: "PaymentTermCRUD",
  props: { dialog: Boolean, myObj: Object },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialog", false);
    },
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj)
        : console.log("false");
    },
  },
};
</script>

<style></style>
